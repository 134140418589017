/// auth service

export const LOGIN = "user/vendor/login";

export const SIGN_UP = "user/vendor/signup";

export const FORGOT_PASSWORD = "user/vendorAuth/forgotPasswordVendor";

export const VERIFY_EMAIL = "user/vendor/checkEmail";

export const STIPE_LINK = "user/vendor/createLink";

export const CHANGE_PASSWORD = "user/vendor/changePassword";

export const LOGOUT = "user/vendor/logout";

export const DASHBOARD = "user/vendor/countWinery";

export const GET_PROFILE = "user/vendor/getProfile";

export const EDIT_PROFILE = "user/vendor/editProfile";

export const FILE_UPLOAD = "user/vendor/uploadInfo";

export const IMAGE_UPLOAD = "user/vendor/wineryImage";
