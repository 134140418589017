import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = ({ show, onHide, onConfirm }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this winery?</Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="fs-14 py-2 px-3"
          onClick={onConfirm}
        >
          Delete
        </Button>
        <Button
          variant="secondary"
          className="fs-14 py-2 px-3"
          onClick={onHide}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
