import React, { useState } from "react";

import lockIcon from "../../images/svg/padlock (6) 1.svg";
import { Modal } from "react-bootstrap";
import { forgotPassword } from "../../services/AuthService";
import { notifyError, notifyTopRight } from "../common/Toaster";

export default function ForgotPassword({ show, onHide }) {
  let errorsObj = { email: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email.trim() === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    try {
      const formData = {email:email}
      const response = await forgotPassword(formData);
      notifyTopRight(response?.data?.message);
      onHide();
    } catch (error) {
      // setApiError(error?.response?.data?.message);
      notifyError(error?.response?.data?.message);
      // console.log(error, " error ");
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header className="p-4" closeButton></Modal.Header>
      <Modal.Body className="text-center">
        <div className="mb-3">
          <h3 className="fs-24">Forgot Password</h3>
        </div>
        <img src={lockIcon} />
        <h3 className="mt-4 fs-18">
          Enter your registered email below to receive password instructions
        </h3>

        <div>
          <input
            type="email"
            className="border-bottom-only"
            placeholder="Enter email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrors((prevErrors) => ({
                ...prevErrors,
                email: "",
              }));
            }}
          />
          {errors.email && (
            <div className="text-danger text-start fs-12">{errors.email}</div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-top-0 pt-0">
        <button
          type="button"
          className="btn btn-primary px-2 w-100"
          onClick={onSubmit}
        >
          Send
        </button>
      </Modal.Footer>
    </Modal>
  );
}
