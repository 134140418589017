import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import { getBookings } from "../../services/Bookings/BookingService";
import moment from "moment";
import { CSVLink } from "react-csv";
import CancelBooking from "../modal/CancelBooking";
import CountUp from "react-countup";
import { RangeDatePicker } from "@y0c/react-datepicker";

export default function MyBookingList(props) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(props.location.state?props.location.state:"total");
  console.log(props.location.state,"props.location.state")
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [initialFetch, setInitialFetch] = useState(true);
  const [dateRangePickerKey, setDateRangePickerKey] = useState(1);
  const limit = 10;
  function onDateChange(...args) {
    setStartDate(moment(args[0]).format("YYYY-MM-DD"));
    setEndDate(moment(args[1]).format("YYYY-MM-DD"));
  }
  // function onDateChange(start, end) {
  //   setStartDate(start ? moment(start).format("YYYY-MM-DD") : null);
  //   setEndDate(end ? moment(end).format("YYYY-MM-DD") : null);
  // }

  function onReset() {
    setDateRangePickerKey((prev) => prev + 1);
    setStartDate("");
    setEndDate("");
  }

  const headers = [
    { label: "USER NAME", key: "userId.name" },
    { label: "USER EMAIL", key: "userId.email" },
    { label: "Category", key: "wineryId.category" },
    { label: "Guest", key: "guests" },
    { label: "Total Amount", key: "amount" },
    { label: "Slot Start Time", key: "startTime" },
    { label: "Slot End Time", key: "endTime" },
    { label: "Booking Date", key: "date" },
  ];
  ///function to fetch table data ///
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getBookings(
        currentPage,
        limit,
        search,
        filter,
        startDate,
        endDate
      );
      console.log(response.data, "data");
      setUsers(response.data.data?.bookings);
      setCount(response.data.data);
      const total = response.data.data?.count;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data?.count);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage, filter, endDate]);

  let timeoutId;
  // Define a helper function for debouncing
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const debouncedHandleFetch = debounce(getTableData, 500);
  useEffect(() => {
    if (initialFetch === false) {
      setCurrentPage(0);
      debouncedHandleFetch();
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [search]);
  return (
    <div>
      <PageTitle activeMenu="Bookings List" motherMenu="Bookings" />
      <div className="row">
        <div
          className="col-xl-3 col-xxl-3 col-lg-6 col-md-6 col-sm-6 pointer"
          onClick={() =>
            props.history.push({
              pathname: "/booking-management",
            })
          }
        >
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="mr-3 bgl-warning text-theme">
                  <i className="flaticon-381-bookmark-1"></i>
                  {/* <img src={svgIcon} width={30} /> */}
                </span>
                <div className="media-body">
                  <h3 className="mb-0 text-black">
                    <span className="counter ml-0">
                      {" "}
                      <CountUp
                        start={0}
                        end={count?.totalBookings || 0}
                        duration={5}
                      />
                    </span>
                  </h3>
                  <p className="mb-0">Total Bookings</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-xl-3 col-xxl-3 col-lg-6 col-md-6 col-sm-6 pointer"
          onClick={() =>
            props.history.push({
              pathname: "/booking-management",
            })
          }
        >
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="mr-3 bgl-warning text-theme">
                  <i className="flaticon-381-bookmark-1"></i>
                  {/* <img src={svgIcon} width={30} /> */}
                </span>
                <div className="media-body">
                  <h3 className="mb-0 text-black">
                    <span className="counter ml-0">
                      {" "}
                      <CountUp
                        start={0}
                        end={count?.activeBookings || 0}
                        duration={5}
                      />
                    </span>
                  </h3>
                  <p className="mb-0">Upcoming Bookings</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-xl-3 col-xxl-3 col-lg-6 col-md-6 col-sm-6 pointer"
          onClick={() =>
            props.history.push({
              pathname: "/booking-management",
            })
          }
        >
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="mr-3 bgl-warning text-theme">
                  <i className="flaticon-381-bookmark-1"></i>
                  {/* <img src={svgIcon} width={30} /> */}
                </span>
                <div className="media-body">
                  <h3 className="mb-0 text-black">
                    <span className="counter ml-0">
                      {" "}
                      <CountUp
                        start={0}
                        end={count?.confirmedBookings || 0}
                        duration={5}
                      />
                    </span>
                  </h3>
                  <p className="mb-0">Completed Bookings</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-xl-3 col-xxl-3 col-lg-6 col-md-6 col-sm-6 pointer"
          onClick={() =>
            props.history.push({
              pathname: "/booking-management",
            })
          }
        >
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="mr-3 bgl-warning text-theme">
                  <i className="flaticon-381-bookmark-1"></i>
                  {/* <img src={svgIcon} width={30} /> */}
                </span>
                <div className="media-body">
                  <h3 className="mb-0 text-black">
                    <span className="counter ml-0">
                      {" "}
                      <CountUp
                        start={0}
                        end={count?.canceledBookings || 0}
                        duration={5}
                      />
                    </span>
                  </h3>
                  <p className="mb-0">Cancelled Bookings</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Card>
        <Card.Header className="d-block">
          <div className="d-block d-md-flex justify-content-between align-items-center">
            <div className="col-3 p-0" style={{ flexGrow: 1 }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="input-group border bg-white input-group-sm"
                  style={{ borderRadius: "8px" }}
                >
                  <input
                    type="text"
                    name="table_search"
                    className="form-control float-right border-0 search-input"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={getTableData}
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <select
                className="form-control"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option hidden>Filter by status</option>
                <option value={"total"}>All</option>
                <option value={"active"}>Upcoming</option>
                <option value={"confirm"}>Completed</option>
                <option value={"cancel"}>Cancelled</option>
              </select>
            </div>
            <RangeDatePicker
              key={dateRangePickerKey}
              className="form-control"
              startText="Start"
              endText="End"
              startPlaceholder="Start Date"
              endPlaceholder="End Date"
              onChange={onDateChange}
              // value={[startDate ? moment(startDate).toDate() : null, endDate ? moment(endDate).toDate() : null]} // controlled value
            />
            {startDate && (
              <button
                type="button"
                className="btn btn-primary py-2"
                onClick={() => onReset()}
              >
                Reset Date
              </button>
            )}
            <CSVLink
              filename={"Booking-List.csv"}
              data={users}
              headers={headers}
            >
              <button
                type="button"
                className="btn btn-primary px-3 py-2"
                disabled={users?.length == 0}
              >
                Export <i class="flaticon-381-send"></i>
              </button>
            </CSVLink>
          </div>
        </Card.Header>
        <Card.Body>
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive className="mb-0">
              <thead className="text-black">
                <tr>
                  <th>
                    <strong>User NAME</strong>
                  </th>
                  <th>
                    <strong>Winery Name</strong>
                  </th>
                  <th>
                    <strong>category</strong>
                  </th>

                  <th>
                    <strong>Guests</strong>
                  </th>
                  <th>
                    <strong>Total Amount</strong>
                  </th>
                  <th>
                    <strong>Booking Slot</strong>
                  </th>

                  <th>
                    <strong>Booking date</strong>
                  </th>
                  <th>
                    <strong>Cancel date</strong>
                  </th>
                  <th>
                    <strong>Status</strong>
                  </th>
                  <th>
                    <strong>Action</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {" "}
                      {item?.userId?.name}
                      <br />
                      {item?.userId?.email}
                    </td>
                    <td>{item?.wineryId?.name}</td>
                    <td>{item?.wineryId?.category}</td>

                    <td>{item?.guests}</td>
                    <td>€{item?.amount}</td>

                    <td>
                      {item?.startTime && item?.endTime
                        ? `${moment(item?.startTime, "HH:mm").format(
                            "hh:mm a"
                          )} - ${moment(item?.endTime, "HH:mm").format(
                            "hh:mm a"
                          )}`
                        : ""}
                    </td>
                    <td>
                      {item?.date
                        ? moment(item?.date).format("DD/MM/YYYY")
                        : ""}
                    </td>
                    <td>{item?.cancelledDate?moment(item?.cancelledDate).format("DD/MM/YYYY"):"-"}</td>

                    <td>
                      {" "}
                      {item?.isCompleted ? (
                        <Badge variant="success light">Completed</Badge>
                      ) : item?.isCancelled ? (
                        <Badge variant="danger light">Cancelled</Badge>
                      ) : (
                        <Badge variant="primary light">Upcoming</Badge>
                      )}
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary fs-12 px-3 py-2"
                        disabled={item?.isCancelled || item?.isCompleted}
                        onClick={() => {
                          setBookingId(item?._id);
                          setShowModal(true);
                        }}
                      >
                        Cancel
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {users?.length === 0 && !loader ? (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          ) : (
            ""
          )}
        </Card.Body>
        {users?.length !== 0 && (
          <div className="card-footer clearfix">
            <div className="d-block d-sm-flex justify-content-between align-items-center">
              <div className="dataTables_info">
                Showing {currentPage * limit + 1} to{" "}
                {listLength > (currentPage + 1) * limit
                  ? (currentPage + 1) * limit
                  : listLength}{" "}
                of {listLength} entries
              </div>
              <Pagination
                pageCount={pageCount}
                pageValue={currentPage}
                setPage={setCurrentPage}
              />
            </div>
          </div>
        )}
        {showModal && (
          <CancelBooking
            show={showModal}
            bookingId={bookingId}
            table={getTableData}
            onHide={() => setShowModal(false)}
          />
        )}
      </Card>
    </div>
  );
}
