import React, { useEffect, useState } from "react";
import { getPolicyTerms } from "../../services/User/UserService";
import parse from "html-react-parser";
import PageTitle from "../layouts/PageTitle";
export default function TermsAndConditions() {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState(null);

  ///function to fetch data ///
  const getData = async () => {
    setLoader(true);
    try {
      const response = await getPolicyTerms();
      setData(response.data?.data?.policy?.termsAndConditions);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return <div> 
    <PageTitle activeMenu="Terms And Conditions" motherMenu="Terms And Conditions" />
    {data && parse(data)}</div>;
}
