export const GET_WINERY = "user/vendor/count";

export const BLOCK_USERS = "admin/user/toggleBlock";

export const POST_WINERY = "user/vendor/createWineries";

export const DELETE_WINERY = "user/vendor/deleteWinery";

export const EDIT_WINERY = "user/vendor/editWinery";

export const GET_POLICY = "user/vendor/getPolicies";
