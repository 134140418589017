import axiosInstance from "../AxiosInstance";
import {
  CANCEL_BOOKING,
  GET_BOOKINGS,
  GET_CHART_DATA,
} from "./BookingEndPoint";

// export function getBookings(currentPage, limit,search,filter,startDate,endDate) {
//   return axiosInstance.get(
//     GET_BOOKINGS + `?page=${currentPage}&limit=${limit}&search=${search}&type=${filter}&startDate=${startDate}&endDate=${endDate}`
//   );
// }
export function getBookings(currentPage, limit, search, filter, startDate, endDate) {
  const params = new URLSearchParams({
    page: currentPage,
    limit: limit,
    search: search,
    type: filter,
  });

  if (startDate) {
    params.append('startDate', startDate);
  }
  if (endDate) {
    params.append('endDate', endDate);
  }

  return axiosInstance.get(`${GET_BOOKINGS}?${params.toString()}`);
}

export function cancelBooking(id) {
  const postData = {
    bookingId: id,
  };
  return axiosInstance.post(CANCEL_BOOKING, postData);
}

export function getChartsData(filter) {
  return axiosInstance.get(GET_CHART_DATA + `?type=${filter}`);
}
