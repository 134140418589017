import axios from "axios";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import {
  CHANGE_PASSWORD,
  DASHBOARD,
  EDIT_PROFILE,
  FILE_UPLOAD,
  FORGOT_PASSWORD,
  GET_PROFILE,
  IMAGE_UPLOAD,
  LOGIN,
  LOGOUT,
  SIGN_UP,
  STIPE_LINK,
  VERIFY_EMAIL,
} from "./AuthApiEndPoints";
import axiosInstance from "./AxiosInstance";
import { notifyError } from "../jsx/common/Toaster";

export function signUp(formData) {
  //axios call
  return axiosInstance.post(SIGN_UP, formData);
}
export function verifyEmail(formData) {
  return axiosInstance.post(VERIFY_EMAIL, formData);
}
export function forgotPassword(formData) {
  //axios call
  return axiosInstance.post(FORGOT_PASSWORD, formData);
}


export function stipeLink() {
  return axiosInstance.post(STIPE_LINK);
}
export function login(email, password) {
  const postData = {
    email,
    password,
  };

  return axiosInstance.post(LOGIN, postData);
}

export function dashboard(filter) {
  return axiosInstance.get(DASHBOARD);
}

export function getProfile() {
  return axiosInstance.get(GET_PROFILE);
}
export function editProfile(fromData) {
  return axiosInstance.put(EDIT_PROFILE, fromData);
}

export function changePasswoard(oldPassword, newPassword) {
  const postData = {
    oldPassword,
    newPassword,
  };

  return axiosInstance.put(CHANGE_PASSWORD, postData);
}
export function fileUpload(file) {
  const formdata = new FormData();
  formdata.append("file", file, "[PROXY]");
  return axiosInstance.post(FILE_UPLOAD, formdata);
}
export function imageUpload(file, id) {
  console.log(file, "file in api function");
  // const postData = { picture: file, id };
  const formdata = new FormData();
  formdata.append("picture", file, "[PROXY]");
  formdata.append("id", id);

  return axiosInstance.post(IMAGE_UPLOAD, formdata);
}

export function logOut() {
  return axiosInstance.post(LOGOUT, { test: "" });
}

export function formatError(errorResponse) {
  notifyError(errorResponse);
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("wineryVendorToken", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("wineryVendorToken");
  // let tokenDetails = "";
  if (!tokenDetailsString) {
    history.push("/");
    dispatch(logout(history));
    return;
  }
  dispatch(loginConfirmedAction(tokenDetailsString));
}
