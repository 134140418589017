import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";

import logotext from "../../images/Logo/Grayscale Transparent.png";
import eye from "../../images/svg/eye.svg";
import eyeClose from "../../images/svg/eyeClose.svg";
import ForgotPassword from "../modal/ForgotPassword";
function Login(props) {
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [apiError, setApiError] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    if (password.length < 6) {
      errorObj.password = "Password length must be at least 6 characters long!";
      error = true;
    }
    if (password.length > 15) {
      errorObj.password =
        "Password length must be less than or equal to 15 characters long!";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, props.history));
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setErrors({
      ...errors,
      email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)
        ? ""
        : "Invalid email format",
    });
  };
  return (
    <div className="login-form-bx">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-7 box-skew d-flex">
            <div className="authincation-content">
              <div className="mb-4">
                <h3 className="mb-1 font-w600">Welcome to Vendor Dashboard</h3>
                <p className="">Sign in by entering information below</p>
              </div>
              {apiError && (
                <div
                  role="alert"
                  className="fade alert-dismissible fade show alert alert-danger show"
                >
                  {apiError}
                </div>
              )}

              <form onSubmit={onLogin}>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Email</strong>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={handleEmailChange}
                    // onChange={(e) => {
                    //   setEmail(e.target.value);
                    //   setErrors({ ...errors, email: "" });
                    // }}
                    placeholder="Email"
                  />
                  {errors.email && (
                    <div className="text-danger fs-12">{errors.email}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Password</strong>
                  </label>
                  <div className="position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setErrors({ ...errors, password: "" });
                      }}
                      placeholder="Password"
                    />
                    <span
                      className="pointer"
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      {showPassword ? (
                        <img src={eye} />
                      ) : (
                        <img src={eyeClose} width={20} />
                      )}
                    </span>
                  </div>

                  {errors.password && (
                    <div className="text-danger fs-12">{errors.password}</div>
                  )}
                </div>
                <p className="mb-3 pointer" onClick={() => setShowModal(true)}>
                  Forgot Password?
                </p>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary btn-block">
                    Sign In
                  </button>
                </div>
              </form>
              <div className="new-account mt-2">
                <p className="mb-0">
                  Don't have an account?{" "}
                  <Link className="text-black" to="/sign-up">
                    Sign up
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-5 d-flex box-skew1">
            <div className="inner-content align-self-center">
              <Link className="login-logo">
                <img src={logotext} alt="" className="logo-text ml-1" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <ForgotPassword
          show={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
