import React from "react";
import yellowStar from "../../images/svg/yellowStart.svg";
import moment from "moment";
import Profile from "../../images/blank-profile-picture.webp";
export default function ReviewCard({ review }) {
  const numberOfStars = typeof review?.star === "number" ? review?.star : 0;
  const imgUrl = "https://staging-api.bookwineries.com/";

  // Generate array of yellow stars based on the number of stars
  const yellowStars = Array.from({ length: numberOfStars }, (_, index) => (
    <img
      key={index}
      src={yellowStar}
      width={20}
      alt="Yellow Star"
      className="mr-2"
    />
  ));
  return (
    <div className="review-card">
      <div className="d-flex justify-content-start align-items-center" style={{gap:"1rem"}}>
        <img
          src={review?.picture ? imgUrl + review?.picture : Profile}
          className="circle"
        />
        <div>
          <h1>{review?.userName}</h1>
          <p>
            {yellowStars} {moment(review?.time).fromNow()}
            {/* <img src={yellowStars} width={20} /> 24mins ago */}
          </p>
        </div>
      </div>
      <p className="review-text">{review?.message}</p>
    </div>
  );
}
